import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { postData, getData } from "../../services/axiosInstance";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../components/Auth_Layout/AuthLayout";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  USER_ADD_VALIDATION_SCHEMA,
  USER_UPDATE_VALIDATION_SCHEMA,
} from "../../utils/formValidations/userSchemaValidation";
import { openNotificationWithIcon } from "../../components/Layout/Toastify/toastify";
import ChangePasswordModal from "../../components/ChangePassword/changePassword";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddUser() {
  // const [listData, setUserList] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.data);
  const [disabled, setDisabled] = useState(false);
  const [openChangePasswordModal, setChangePasswordModal] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      id ? USER_UPDATE_VALIDATION_SCHEMA : USER_ADD_VALIDATION_SCHEMA
    ),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      role: [],
      userId: "",
      isActive: false
    },
  });

  const getUserDetails = async () => {
    if (id) {
      // get data from zip code
      const url = `/user/${id}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {

          const user = response.data.data;
          setValue("name", user.name);
          setValue("email", user.email);
          setValue("role", user.roles);
          setValue("userId", user?._id)
          setValue("isActive", user?.isActive)
          return response.data.data;
        }
      } catch (error) {
        return {};
      }
    }
  };

  useEffect(() => {
    if (
      !(
        userData.roles.includes("superadmin") ||
        userData.roles.includes("admin")
      )
    ) {
      navigate("/");
    }

    getUserDetails();
  }, []);

  const submitData = async (values) => {
    setDisabled(true);
    let payload = {};
    let url = id ? `/user/update/${id}` : "/user/add";
    payload = {
      name: values.name,
      email: values.email,
      password: values.password,
      roles: values.role,
      isActive: values.isActive || false
    };
    if (id) {
      delete payload.password;
    }
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
        setTimeout(() => {
          navigate("/users");
        }, 100);
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
      setDisabled(false);
    }
  };

  const role = ["admin", "agent", "manager"];

  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
          <Link underline="hover" color="inherit" href="/users">
            Users
          </Link>
          <Typography color="text.primary">{id ? "Update" : "Add"}</Typography>
        </Breadcrumbs>

        {/* main page information area  */}
        <Paper elevation={3}>
         {id && <Box p={2}>
            <Button
              variant="text"
              sx={{
                marginTop: "5px",
                marginLeft: "40px",
                width: "15%",
              }}
              onClick={() => setChangePasswordModal(true)}
            >
              Change Password
            </Button>
          </Box>}
          <Box
            component="form"
            pt={5}
            pb={5}
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              background: "#fff",
              width: "100%",
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(submitData)}
          >
            <div>

              <Grid container spacing={1} ml={3}>
                <Grid item>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        id="outlined-required"
                        label="User name"
                        variant="outlined"
                        placeholder="Enter user Name"
                        error={Boolean(errors.name)} // Show error state if there's an error
                        helperText={errors.name ? errors.name.message : ""} // Display error message as helper text
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        id="outlined-required"
                        label="Email"
                        variant="outlined"
                        placeholder="Enter email"
                        type={"email"}
                        error={Boolean(errors.email)} // Show error state if there's an error
                        helperText={errors.email ? errors.email.message : ""} // Display error message as helper
                      />
                    )}
                  />
                </Grid>
                {!id && (
                  <Grid item>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="outlined-required"
                          label="Password"
                          variant="outlined"
                          placeholder="Enter password"
                          type={"password"}
                          error={Boolean(errors.password)} // Show error state if there's an error
                          helperText={errors.password ? errors.password.message : ""} // Display error message as helper
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ m: 1, minWidth: 250 }} error={Boolean(errors.role)}>
                        <InputLabel id="demo-multiple-name-label">Role</InputLabel>
                        <Select
                          {...field}
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          input={<OutlinedInput label="Role" />}
                          MenuProps={MenuProps}
                          variant="filled"
                        >
                          {role.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.role && (
                          <FormHelperText>{errors.role.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid container spacing={1} ml={3}>
                  <Grid item >
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Activate Account"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                disabled={disabled}
                sx={{
                  marginTop: "5px",
                  marginLeft: "40px",
                  width: "15%",
                }}
                type="submit"
              >
                {id ? "Update" : "Save"}
              </Button>
            </div>
          </Box>
        </Paper>
        <ChangePasswordModal
          open={openChangePasswordModal}
          onClose={() => setChangePasswordModal()}
          userId={getValues("userId")}
        />
      </Box>
    </AuthLayout>
  );
}
