import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

import { v4 as uuidv4 } from "uuid";
import { Controller } from "react-hook-form";

const OtherCharges = ({ fields, control,remove, append,   }) => {
    const handleDelete = (index) => {
      remove(index)
  };

  const handleAddRow = () => {
      const newId = uuidv4();
      append({ id: newId, type: "", amount: "" })
  };
  return (
    <>
      {fields?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              {fields?.length > 0 ? (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "4px 8px" }}>Type</TableCell>
                      <TableCell style={{ padding: "4px 8px" }}>
                        Amount
                      </TableCell>
                      <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    </TableRow>
                  </TableHead>
                </>
              ) : (
                ""
              )}

              <TableBody>
                {fields?.map((row, index) => (
                  <TableRow key={row.id} style={{ padding: "4px 8px" }}>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Controller
                        name={`otherCharges.${index}.type`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { padding: "0px" }, // No padding around the input
                              inputProps: {
                                style: { padding: "4px 8px", fontSize: "15px" }, // Padding inside the input
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Controller
                        name={`otherCharges.${index}.amount`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { padding: "0px" }, // No padding around the input
                              inputProps: {
                                style: { padding: "4px 8px", fontSize: "15px" },
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      {/* <IconButton
                        color="info"
                        onClick={() => handleAdd(row.id)}
                        aria-label="add"
                      >
                        <CheckIcon />
                      </IconButton> */}
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  <TableCell style={{ padding: "4px 8px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddRow}
                    >
                      Add More
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={handleAddRow}>
            Add other charges
          </Button>
        </>
      )}
    </>
  );
};

export default OtherCharges;
