import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  Grid,
  Paper,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import UpdateLoadStatus from "../UpdateLoadStatus/UpdateLoadStatus";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: "capitalize",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns = [
  {
    id: 1,
    title: "#",
  },
  {
    id: 2,
    title: "Status",
  },
  {
    id: 3,
    title: "Updated By",
  },
  {
    id: 4,
    title: "Updated At",
  },
  {
    id: 5,
    title: "Description",
  },
];

const LoadStatus = ({
  shipperId,
  shipperLoadId,
  fetchShipperLoadData,
  shipperLoadData,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [statusHistory,setStatusHistory] = useState([]);
  const closeModalFunction = (state) => {
    setOpenAddModal(false);
    if (state?.refetch) {
      fetchShipperLoadData();
    }
  };
  useEffect(() => {
    if (shipperLoadData?.statusHistory) {
      setStatusHistory(shipperLoadData?.statusHistory);
    }
  }, [shipperLoadData]); 
  return (
    <Box sx={{ m: 2, p: 2, backgroundColor: "#ffffff", width: "100%" }}>
      <Button
        sx={{
          marginTop: 2,
          marginLeft: 4,
          marginBottom: 2,
          width: "20%",
        }}
        onClick={() => setOpenAddModal(true)}
        variant="contained"
      >
        Update Load Status
      </Button>
      <Paper elevation={3} sx={{ m: 4, maxWidth: "90%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns?.map((col) => {
                  return (
                    <TableCell key={`key-${col?.id}`}>{col.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {statusHistory.map((option, index) => {
                return (
                  <StyledTableRow key={`key-${index + 1}`}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{option?.loadStatus}</StyledTableCell>
                    <StyledTableCell>{option?.updatedBy?.name}</StyledTableCell>
                    <StyledTableCell>{option?.updatedAt}</StyledTableCell>
                    <StyledTableCell>{option?.loadDescription}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <UpdateLoadStatus
        openAddModal={openAddModal}
        closeModalFunction={closeModalFunction}
        shipperId={shipperId}
        shipperLoadId={shipperLoadId}
      />
    </Box>
  );
};

export default LoadStatus;
