import React, { useEffect } from "react";

import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const isObjectWithKeyValuePairs = (variable) => {
  return (
    variable !== null &&
    typeof variable === "object" &&
    !Array.isArray(variable) &&
    Object.keys(variable).length > 0
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableData = (title, data, counter) => {
  return (
    <Card sx={{ minWidth: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} size={"small"} aria-label="table">
              <TableBody>
                {Object.keys(data || [])?.map((key, index) => {
                  if (
                    typeof data[key] == "string" ||
                    typeof data[key] == "number" ||
                    typeof data[key] == "boolean"
                  ) {
                    return (
                      <StyledTableRow key={key + "-" + index}>
                        <StyledTableCell align="left">
                          <strong>{key}</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {`${data[key]}`}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  } else if (Array.isArray(data[key])) {
                    return data[key]?.map((item, index) => {
                      return (
                        <>
                          <StyledTableRow key={key + index}>
                            <StyledTableCell align="left">
                              <strong>{`${key}-${index + 1}`}</strong>
                            </StyledTableCell>
                          </StyledTableRow>
                          {TableData(key, item, counter + 1)}
                        </>
                      );
                    });
                  } else if (isObjectWithKeyValuePairs(data[key])) {
                    const item1 = data[key];
                    if (counter < 3) {
                      return <>{TableData(key, item1, counter + 1)}</>;
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

const DetailTable = ({ data, title }) => {
  const [tableState, setTableState] = useState(null);
  useEffect(() => {
    const tableDetail = TableData(title, data, 1);
    setTableState(tableDetail);
  }, []);
  return (
    data && (
      <>{tableState}</>
      // <Card sx={{ minWidth: "100%" }}>
      //   <CardContent>
      //     <Typography variant="h6" color="primary">
      //       {title}
      //     </Typography>
      //     <Box mt={2}>
      //       <TableContainer component={Paper}>
      //         <Table
      //           sx={{ minWidth: "100%" }}
      //           size={"small"}
      //           aria-label="table"
      //         >
      //           <TableBody>
      //             {Object.keys(data)?.map((key, index) => {
      //               if (
      //                 typeof data[key] == "string" ||
      //                 typeof data[key] == "number" ||
      //                 typeof data[key] == "boolean"
      //               ) {

      //                 return (
      //                   <StyledTableRow key={key + "-" + index}>
      //                     <StyledTableCell align="left">
      //                       <strong>{key}</strong>
      //                     </StyledTableCell>
      //                     <StyledTableCell align="right">
      //                       {`${data[key]}`}
      //                     </StyledTableCell>
      //                   </StyledTableRow>
      //                 );
      //               } else if (Array.isArray(data[key])) {
      //                 return data[key]?.map((item, index) => {
      //                   return (
      //                     <>
      //                       <StyledTableRow key={key + index}>
      //                         <StyledTableCell align="left">
      //                           <strong>{`${key}-${index + 1}`}</strong>
      //                         </StyledTableCell>
      //                       </StyledTableRow>

      //                       {Object.keys(item)?.map((i, index) => {
      //                         return (
      //                           <StyledTableRow key={`${i}-${index}`}>
      //                             <DetailTable data={item} title={i} />
      //                           </StyledTableRow>
      //                         );
      //                       })}
      //                     </>
      //                   );
      //                 });
      //               } else if (isObjectWithKeyValuePairs(data[key])) {
      //                 const item1 = data[key];
      //                 return (
      //                   <>
      //                     {Object.keys(item1 || [])?.map((i, index) => {
      //                       return (
      //                         <StyledTableRow key={`${i}-${index}`}>
      //                           <DetailTable data={item1} title={i} />
      //                         </StyledTableRow>
      //                       );
      //                     })}
      //                   </>
      //                 );
      //               } else {
      //                 return null;
      //               }
      //             })}
      //           </TableBody>
      //         </Table>
      //       </TableContainer>
      //     </Box>
      //   </CardContent>
      // </Card>
    )
  );
};

export default DetailTable;
