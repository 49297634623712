import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button } from "@mui/material";
import { getData } from "../../services/axiosInstance";
import moment from "moment";
import AuthLayout from "../../components/Auth_Layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import DOTModal from "../../components/DOTModal/DOTModal";
import ChangeCarrierStatus from "../../components/Carrier/CarrierStatus";
import { columns, statusColor, statusKeys, StyledTableCell, StyledTableRow } from "./data";



export default function Carriers() {
  const [listData, setListData] = useState([]);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [statusKey, setStatusKey] = useState("all");
  async function fetchData() {
    const url = `/address/carrier/view?status=` + statusKey;
    try {
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data || []);
      }
    } catch (error) {
      setListData([]);
    }
  }

  useEffect(() => {
    fetchData();
  }, [statusKey]);


    const changeStatus = (shipperId) => {
      setSelectedCarrier(shipperId);
      // console.log({shipperId})
    setOpenChangeStatus(true);
  }

  const openModalFunction = () => {
    setOpenAddModal(true);
  };
  const closeModalFunction = (state) => {
    setOpenAddModal(false);
    setOpenChangeStatus(false)
    if (state?.refetch) {
      fetchData();
    }
  };

  const onClickButton = async (val) => {
    setStatusKey(val);
  };

  return (
    <AuthLayout>
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/Shipper">
              Carriers
            </Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
          <Button onClick={() => openModalFunction()} variant="contained">
            Add
          </Button>
        </Box>
        {Object.keys(statusKeys).map((oneKey, i) => {
          return (
            <>
              <Button
                mr={1}
                key={i}
                variant={statusKey === oneKey ? "contained" : "outlined"}
                onClick={() => onClickButton(oneKey)}
                size="small"
                sx={{
                  margin: "2px",
                  fontSize: '0.70rem', // Smaller font size
                  padding: '4px 8px', // Adjust padding to make it even smaller
                }}
              >
                {statusKeys[oneKey]}
              </Button>
            </>
          );
        })}
        <Paper elevation={3}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <StyledTableCell key={`key-${col.id}`} align={col.id === 1 ? "left" : "center"}>
                        {col.title}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.allData?.LegalName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.dotNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.mcNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row?.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => navigate(`/carrier-info/${row._id}`)}
                        variant="contained"
                        size="small"
                        color={row.status !== 'active' ? 'error' : 'success'}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                      <StyledTableCell
                      align="right" onClick={() => changeStatus(row._id)}>
                      <span
                        variant="body1"
                        style={{
                          textDecoration: 'none', // Remove default underline
                          borderBottom: '1px dotted black', // Add a dotted underline
                          cursor: 'pointer', // Change cursor to pointer
                          color: statusColor[row.status]
                        }}
                      >
                        {row.status}
                      </span>

                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={1}
              page={5}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Paper>
      </Box>
      <DOTModal
        openAddModal={openAddModal}
        closeModalFunction={closeModalFunction}
      // shipperId={shipperId}
      // shipperLoadId={shipperLoadId}
      />
      <ChangeCarrierStatus onClose={closeModalFunction} open={openChangeStatus} carrierId={selectedCarrier} />
    </AuthLayout>
  );
}
