import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postData } from "../../services/axiosInstance";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/^\S*$/, "Password must not contain spaces"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePasswordModal = ({ open, onClose, userId }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    // mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      userId: userId,
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (userId) {
      setValue("userId", userId);
    }
  }, [userId, setValue]);

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const submitData = async (data) => {
    const payload = data;
    delete payload.confirmPassword;
    try {
      const url = `/user/update/password`;
      const response = await postData(url, payload);
      if (response.status === 200) {
        onCloseModal();
      }

      //   if (response.status === 200) {
      //     // Call onDeleteSuccess callback to update the UI after successful deletion
      //     onDeleteSuccess();
      //     onClose(); // Close the modal
      //   } else {
      //     // Handle errors or show a message to the user
      //     console.error("Failed to delete the address");
      //   }
    } catch (error) {
      console.error("Error deleting the address:", error);
    }
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Change Password</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography sx={{ mt: 2 }}>Please enter new password.</Typography>
        {/* <Grid container  > */}
        <Box
          component="form"
          pt={5}
          pb={5}
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            background: "#fff",
            width: "100%",
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(submitData)}
        >
          <Grid item xs={2.5}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="New password"
                  variant="outlined"
                  placeholder="Enter new password"
                  type="password"
                />
              )}
            />
            {errors.password ? (
              <span className="text-rose-500 text-left text-sm">
                {errors?.password?.message}
              </span>
            ) : (
              <span className="h-4"></span>
            )}
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Confirm password"
                  variant="outlined"
                  placeholder="Enter password"
                  type="password"
                />
              )}
            />
            {errors.confirmPassword ? (
              <span className="text-rose-500 text-left text-sm">
                {errors?.confirmPassword?.message}
              </span>
            ) : (
              <span className="h-4"></span>
            )}
          </Grid>
          {/* </Grid> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              // onClick={onClose}
              type="submit"
              sx={{ mr: 2 }}
              variant="outlined"
              color="primary"
            >
              Confirm
            </Button>
            {/* <Button onClick={handleDelete} variant="contained" color="error">
            OK
          </Button> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
