import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button, Switch } from "@mui/material";
import { getData, postData } from "../../services/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import AuthLayout from "../../components/Auth_Layout/AuthLayout";
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { openNotificationWithIcon } from "../../components/Layout/Toastify/toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Workspaces() {
  const userData = useSelector((state) => state?.user?.data);
  const [listData, setWorkspacesList] = useState([]);
   const [open, setOpen] = useState(false);
   const [workspaceId, setWorkspaceId] = useState("");
  const navigate = useNavigate();
  const openModalFunction = () => {
    navigate("/workspaces/add");
  };

  const getWorkspaceList  = async () => {
      try {
        const url = "workspaces/list";
        const response = await getData(url);
        if (response.status === 200) {
          setWorkspacesList(response?.data?.data||[]);
        }
      } catch (error) {
        setWorkspacesList([]);
      }
    };
  useEffect(() => {
    if (
      !(
        userData.roles.includes("superadmin") ||
        userData.roles.includes("admin")
      )
    ) {
      navigate("/");
    }

    getWorkspaceList();
  }, []);

const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const columns = [
    {
      id: 1,
      title: "Name",
    },
    {
      id: 2,
      title: "created At",
    },
    {
      id: 3,
      title: "Action",
    },
        {
      id: 4,
      title: "In Use",
    },
    {
      id: 5,
      title: "Status",
    },
  ];


    const handleChange = (event, workspaceId) => {

    if (event.target.checked) {
      setOpen(true);
      setWorkspaceId(workspaceId)
    }
    };
  const [openStatusChange, setStatusChange] = useState({
    state: false,
    workspace: null,
  })
  const handleWorkspaceStatusChange = (event, workspace) => {
    setStatusChange(() => {
      return {state: true, workspace: workspace}
    })
  }
    const handleClose = () => {
      setOpen(false);
      setStatusChange(() => {
      return {state: false, workspace: null}
    })
  };

  const handleConfirm = async () => {
    const url = `/user/workspace/${workspaceId}`;
    try {
      const response = await getData(url);
      if (response.status === 200) {
      localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
    }

    setOpen(false);
  };

    const handleStatusChange = async () => {
      const url = `/workspaces/update/${openStatusChange.workspace._id}`;
      const payload = {
        workspaceStatus: !openStatusChange.workspace.workspaceStatus
      }
      handleClose()
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        getWorkspaceList()
        openNotificationWithIcon('success', response?.data?.message);
      }
    } catch (error) {
      console.log({ error });
    }

    setOpen(false);
  };


  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/users">
              Workspaces
            </Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>

          <Button variant="contained" onClick={() => openModalFunction()}>
            Add
          </Button>
        </Box>
        {/* main page information area  */}
        <Paper elevation={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <TableCell key={`key-${col.id}`}>{col.title}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((row) => (
                  <StyledTableRow key={row?._id}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>

                    <StyledTableCell>
                      {moment(row.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button variant="contained" onClick={() => navigate(`/workspace/edit/${row?._id}`) }>Edit</Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* <Button variant="contained">View</Button> */}
                      <Switch {...label}        
                       checked={userData.workspaceId?._id === row._id}
                       disabled={userData.workspaceId?._id === row._id}
                       onChange={(e) =>handleChange( e, row?._id )} 
                       />
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* <Button variant="contained">View</Button> */}
                      <Switch {...label}        
                       checked={row.workspaceStatus}
                       disabled={userData.workspaceId?._id === row._id}
                       onChange={(e) =>handleWorkspaceStatusChange( e, row )} 
                       />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={10}
              page={5}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure to change the workspace? You will be logged out.</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
        </Dialog>
        <Dialog open={openStatusChange?.state} onClose={handleClose}>
        <DialogTitle>Are you sure to {`${openStatusChange?.workspace?.workspaceStatus ? "deactivate": "activate"}`} the workspace?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </AuthLayout>
  );
}
