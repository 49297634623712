import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LoadStatus from "../../utils/LoadStatus/loadStatus";
import { useSelector } from "react-redux";
import { useState } from "react";

const schema = yup.object().shape({
  loadStatus: yup.string().required('Load status is required'),
  loadDescription: yup.string().required('Load description is required'),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateLoadStatus = ({
  openAddModal,
  closeModalFunction,
  shipperId,
  shipperLoadId,
  addressType,
}) => {
  const userData = useSelector((state) => state?.user?.data);
  const [loadStatusButtons, setLoadStatusButtons] = useState([]);
  const [statusWarning, setStatusWarning] = useState('');
  useEffect(() => {

    if (userData) {
      let objs = [];
      for (let o of LoadStatus) {
        if (o.access.some(role => userData.roles.includes(role))) {
          objs.push(o);
        }
      }
      setLoadStatusButtons(objs);
    }
  }, [userData]);

  const handleStatusChange = (status) => {
    setStatusWarning('');
    if (status && status === 'deleted') {
      setStatusWarning("Warning: You are going to set the status of this load to 'Deleted'. This action cannot be undone and can only be reversed by a Super Admin. If you are not aware of the consequences, please leave this screen now.")
    }
    console.log(status)
  }

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      loadStatus: "",
      loadDescription: "",
    },
  });

  const modalsubmithandler = async (values) => {
    const payload = {
      ...values,
    };
    // return;
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/loadStatus`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        reset();
        closeModalFunction({ refetch: true });
      }
    } catch (error) {
      // setDisabled(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAddModal}
      onClose={closeModalFunction}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddModal}>
        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit(modalsubmithandler)}
            noValidate
            // onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <div>
              <Typography variant="h6">Update Load Status</Typography>
            </div>
            <Controller
              name="loadStatus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  label="Load Status"
                  variant="outlined"
                  placeholder="Load Status"
                  fullWidth
                  select
                  onChange={(e) => {
                    field.onChange(e); // Call react-hook-form's onChange
                    handleStatusChange(e.target.value); // Custom onChange handler
                  }}
                >
                  {loadStatusButtons.map((obj) => (
                    <MenuItem key={obj._id} value={obj._id}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Typography variant="caption" color='error' size='small'>{statusWarning}</Typography>
            {errors.loadStatus && <Typography color="error" variant="body2">{errors.loadStatus.message}</Typography>}
            <Controller
              name="loadDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  margin="normal"
                  id="outlined-required"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter Description"
                  fullWidth
                />
              )}
            />
            {errors.loadDescription && <Typography color="error" variant="body2">{errors.loadDescription.message}</Typography>}

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Update Status
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeModalFunction}
              sx={{ ml:3,mt: 3, mb: 2 }}
            >
              Close
            </Button>
            <Grid
              container
              sx={{
                textAlign: "center",
              }}
            >
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateLoadStatus;
