import React, { useState } from "react";
import { Box } from "@mui/material";
import TabsOption from "../../components/Layout/TabsOption/TabsOption";
import { useParams } from "react-router-dom";
import { getData } from "../../services/axiosInstance";
import AuthLayout from "../../components/Auth_Layout/AuthLayout";

export default function AddShipper() {
  const { shipperId } = useParams();
  const [shipperData, setShipperData] = useState(null);

  const fetchShipperData = async () => {
    if (shipperId) {
      // get data from zip code
      const url = `/address/shipper/view/${shipperId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          setShipperData(response.data.data);
          return response.data.data;
        }
      } catch (error) {
        return {};
      }
    }
  };

  return (
    <AuthLayout>
      <Box
        key={ `${shipperId}`}
        sx={{
          display: "flex",
          //background: "#e6e6e6",
          width: "100%",
        }}
      >
        <Box mt={12} ml={2} sx={{ width: "100%" }}>
          {shipperId &&shipperData?.shipTag ? (<>
            <b>Shipper : </b><small>{shipperData?.shipTag}</small>&nbsp;&nbsp;
            <b>Owned By : </b><small>{shipperData?.addedByname}</small>&nbsp;&nbsp;
            <b>Created By : </b><small>{shipperData?.addedByname}</small>&nbsp;&nbsp;
            </>
          ) : (
            <b>Add Customer</b>
          )}
          <TabsOption
            shipperId={shipperId}
            ShipperData={shipperData}
            fetchShipperData={fetchShipperData}
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}
