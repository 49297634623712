import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getData } from '../../services/axiosInstance';
import { useNavigate } from 'react-router-dom';


const SearchModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    const handleClose = () => {
        setOpen(false)
        setDebouncedSearchTerm('')
        setSearchTerm('')
    };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms debounce delay

    // Cleanup timeout if user types within 500ms
    return () => {
        clearTimeout(handler);
    };
  }, [searchTerm]);
    
     const fetchData = async (searchQuery) =>  {
      const url = `/address/shipper/view?searchText=${encodeURIComponent(searchQuery)}`;
      try {
        const response = await getData(url);
          if (response.status === 200) {
              //   setListData(response?.data?.data);
              setFilteredItems(response?.data?.data);
        }
      } catch (error) {
        console.log({ error });
      }
    }

  useEffect(() => {
    if (debouncedSearchTerm) {

      fetchData(debouncedSearchTerm)
    } else {
      setFilteredItems([]);
    }
  }, [debouncedSearchTerm]);

  const closeModel = (item) => {
    handleClose()
    navigate(`/shipper/${item._id}/load`)

  }

  return (
    <div>
      
      <Modal open={open} onClose={handleClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="h2">
              Search Shippers
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ marginBottom: '16px', marginTop: '16px' }}
          />

          <List>
            {filteredItems.length > 0 ? (
            <List>
              {filteredItems.map(item => (
                <ListItem key={item.id}>
                  <ListItemText primary={item?.companyName} secondary={item.type} />
                  <ListItemSecondaryAction>
                    {/* <Button onClick={() => navigate(`/shipper/edit/${item._id}`)} variant="contained" color="primary" size="small" sx={{ marginRight: '8px' }}>
                      View Load
                    </Button> */}
                    <Button onClick={() => closeModel(item)} variant="outlined" color="secondary" size="small">
                      Add Load
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" align="center" sx={{ marginTop: '16px' }}>
              No items found.
            </Typography>
          )}
          </List>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchModal;