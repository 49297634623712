import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button, TablePagination } from "@mui/material";
import { getData } from "../../services/axiosInstance";
import moment from "moment";
import AuthLayout from "../../components/Auth_Layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import ChangeSipperstatus from "../../components/shipper/shipperStatus/changeShipperStatus";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976d2',
    color: theme.palette.common.white,
    fontSize: 13,
    padding: 10,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textTransform: "capitalize",
    padding: 10,
    textAlign: 'center'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Shipper() {
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const [openChangeSipperstatus, setOpenChangeSipperstatus] = useState(false);
  const [changeStatusShipId, setChangeStatusShipId] = useState(null);
  const changeStatus = (shipperId) => {
    setChangeStatusShipId(shipperId);
    setOpenChangeSipperstatus(true);
  }
  const onCloseSatusModal = () => {
    setOpenChangeSipperstatus(false);
    fetchData();
  }
  async function fetchData() {
    const url = `/address/shipper/view`;
    try {
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data || []);
      }
    } catch (error) {
      setListData([]);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      id: 1,
      title: "Id",
    },
    {
      id: 2,
      title: "Status",
    },
    {
      id: 3,
      title: "Credit Status",
    },
    {
      id: 4,
      title: "Company Name",
    },
    {
      id: 5,
      title: "Email",
    },
    {
      id: 6,
      title: "DBA",
    },
    {
      id: 7,
      title: "Phone",
    },
    {
      id: 8,
      title: "City",
    },
    {
      id: 9,
      title: "State",
    },
    {
      id: 10,
      title: "Created At",
    },
    {
      id: 11,
      title: "Action",
    },
  ];
  const statusColor = {
    approved:'green',
    rejected:'red',
    review:'blue'
  }
  return (
    <AuthLayout>
      <ChangeSipperstatus
        open={openChangeSipperstatus}
        onClose={() => onCloseSatusModal()}
        shipperId={changeStatusShipId}
      />
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
          <Link underline="hover" color="inherit" href="/Shipper">
            Shippers
          </Link>
          <Typography color="text.primary">List</Typography>
        </Breadcrumbs>

        <Paper elevation={3}>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <StyledTableCell key={`key-${col.id}`} align="center">
                        {col.title}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.shipTag}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right" onClick={() => changeStatus(row._id)}>
                      <span
                        variant="body1"
                        style={{
                          textDecoration: 'none', // Remove default underline
                          borderBottom: '1px dotted black', // Add a dotted underline
                          cursor: 'pointer', // Change cursor to pointer
                          color:statusColor[row.shipperStatus]
                        }}
                      >
                        {row.shipperStatus}
                      </span>

                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.credit}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.companyName}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>
                    <StyledTableCell align="right">N/A</StyledTableCell>
                    <StyledTableCell align="right">{row.phone}</StyledTableCell>
                    <StyledTableCell align="right">{row.city}</StyledTableCell>
                    <StyledTableCell align="right">{row.state}</StyledTableCell>
                    <StyledTableCell align="right">
                      {moment(row.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        onClick={() => navigate(`/shipper/${row._id}`)}
                        variant="contained"
                        size="small"
                      >
                        Info
                      </Button>
                      &nbsp;
                      <Button
                        onClick={() => navigate(`/shipper/edit/${row._id}`)}
                        variant="contained"
                        size="small"
                      >
                        Edit
                      </Button>
                      &nbsp;
                      <Button
                        onClick={() => navigate(`/shipper/${row._id}/load`)}
                        variant="contained"
                        size="small"
                      >
                        Loads
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={10}
              page={0}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Paper>
      </Box>
    </AuthLayout>
  );
}
