import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import shiperInputData from "../../data.json";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import { yupResolver } from "@hookform/resolvers/yup";
import { shipperPaymentValidation } from "../../utils/shipperValidationSchema";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaymentDetails({
  shipperId,
  fetchShipperData,
  ShipperData,
}) {
  const userData = useSelector((state) => state?.user?.data);
  const [rowData, setRowData] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(shipperPaymentValidation),
    defaultValues: {
      isPrePaid: false,
      paymentTerms: "",
      paymentMethod: "",
      creditLimitRequested: "",
      methodEmail: false,
      methodPaper: false,
    },
  });

  const onSelectDocument = (e) => {
    const isItemAlreadyInArray = rowData.some(
      (item) => item.title === e.target.value
    );
    if (!isItemAlreadyInArray) {
      setRowData((prev) => {
        const obj = { title: e.target.value, required: true };
        return [...prev, obj];
      });
    }
    e.target.value = "";
  };

  const onCheckDoc = (e, title) => {
    setRowData((prev) =>
      prev.map((item) => {
        if (item.title === title) {
          return { ...item, required: e.target.checked };
        } else {
          return item;
        }
      })
    );
  };
  const submitData = async (values) => {
    // console.log({ values });
    const payload = {
      isPrePaid: values.isPrePaid,
      paymentTerms: values.paymentTerms,
      paymentMethod: values.paymentMethod,
      methodOfInvoice: {
        email: values.methodEmail,
        paper: values.methodPaper,
      },
      creditLimitRequested: values.creditLimitRequested,
      preferDocument: rowData,
    };

    const url = `/address/shipper/paymentDetails/${shipperId}`;

    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data.message);
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  const getData = async () => {
    const response = await fetchShipperData();
    if (response.paymentDetails) {
      Object.keys(response.paymentDetails).forEach((key) => {
        if (key === "preferDocument") {
          setRowData(response.paymentDetails[key]);
        } else if (key === "methodOfInvoice") {
          setValue("methodEmail", response.paymentDetails[key]?.email);
          setValue("methodPaper", response.paymentDetails[key]?.paper);
        } else setValue(key, response.paymentDetails[key]);
      });
    }

    // const { segments } = response;
    // setData(segments);
  };

  const columns = [
    {
      id: 1,
      title: "Title",
    },
    {
      id: 2,
      title: "Required",
    },
    {
      id: 3,
      title: "Action",
    },
  ];

  const deleteEntry = (title) => {
    setRowData((prev) => prev.filter((elem, index) => elem.title !== title));
  };

  useEffect(() => {
    if (shipperId) {
      getData();
    }
  }, [shipperId]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submitData)}
      pl={1}
      pt={5}
      pb={5}
      sx={{
        background: "#fff",
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      <div
        style={{
          marginLeft: 40,
          marginBottom: 10,
        }}
      >
        Payment Details
      </div>
      <div
        style={{
          marginLeft: 40,
        }}
      >
        <Controller
          name="isPrePaid"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...field} required checked={field.value} />}
                label="Is this Shipper pre-paid ?"
              />
            </FormGroup>
          )}
        />
      </div>
      <div>
        <Grid container spacing={1} ml={3}>
          <Grid item xs={2.5}>
            <Controller
              name="paymentTerms"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  id="outlined-select-currency"
                  variant="filled"
                  select
                  label="Payment Terms"
                >
                  {shiperInputData?.payment_terms?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.paymentTerms?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="paymentMethod"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  id="outlined-select-currency"
                  variant="filled"
                  select
                  label="Payment Method"
                >
                  {shiperInputData?.payment_type?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.paymentMethod?.message}</p>
          </Grid>

          <Grid item xs={2.5}>
            <Controller
              name="creditLimitRequested"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Credit Limit Request"
                  variant="filled"
                  placeholder="Enter Credit Limit"
                  onKeyDown={(e) => {
                      // Prevent non-numeric key presses
                      if (
                        !/[0-9]/.test(e.key) && // allow only number keys
                        e.key !== "Backspace" && // allow backspace
                        e.key !== "Delete" && // allow delete
                        e.key !== "ArrowLeft" && // allow left arrow for navigation
                        e.key !== "ArrowRight" // allow right arrow for navigation
                      ) {
                        e.preventDefault();
                      }
                    }}
                  disabled={!userData.roles.includes("superadmin")}
                />
              )}
            />
            <p style={{ color: "red" }}>
              {errors?.creditLimitRequested?.message}
            </p>
          </Grid>
          <Grid item xs={2.5}>
            <FormControl component="fieldset">
              <>
                <FormLabel
                  component="legend"
                  sx={{ marginLeft: 2, marginBottom: 0 }}
                >
                  Method of Invoicing
                </FormLabel>
                <FormGroup
                  aria-label="position"
                  row
                  sx={{ marginLeft: 2, marginTop: 0 }}
                >
                  <Controller
                    name="methodEmail"
                    control={control}
                    label="Email"
                    defaultValue=""
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Email"
                      />
                    )}
                  />

                  <Controller
                    name="methodPaper"
                    control={control}
                    label="Paper"
                    defaultValue=""
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Paper"
                      />
                    )}
                  />
                </FormGroup>
              </>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          marginLeft: 40,
          marginBottom: 10,
        }}
      >
        Prefer Documents
      </div>
      <div>
        <Grid container ml={3} sx={{ m: 4, width: "100%" }}>
          <Grid item xs={2.5}>
            {/* <Controller
              name="paymentTerms"
              control={control}
              defaultValue=""
              render={({ field }) => (
                // <TextField
                //     {...field}
                //     required
                //     
                //     label="First Name" variant="filled" placeholder='Enter First Name' autoComplete='off'
                // />
                <TextField
                  {...field}
                  required
                  
                  id="outlined-select-currency"
                  variant="filled"
                select
                  label="Payment Terms"
                >
                  {shiperInputData?.payment_terms?.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            /> */}
            <TextField
              required
              fullWidth
              id="outlined-select-currency"
              variant="filled"
              select
              label="Select Document"
              onChange={(e) => onSelectDocument(e)}
              value=""
            >
              {shiperInputData?.prefer_document?.map((option, index) => (
                <MenuItem key={`${option}-${index}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <Paper elevation={3} sx={{ m: 4, maxWidth: "60%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns?.map((col) => {
                  return (
                    <TableCell key={`key-${col?.id}`}>{col.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.map((row, index) => (
                <StyledTableRow key={row.title}>
                  <StyledTableCell component="th" scope="row">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row.required}
                          onChange={(e) => onCheckDoc(e, row.title)}
                        />
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button variant="outlined">
                      <DeleteIcon onClick={() => deleteEntry(row.title)} />
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Button
        variant="contained"
        sx={{
          marginTop: "10px",
          marginLeft: "40px",
          width: "20%",
        }}
        type="submit"
      >
        {shipperId ? "Update" : "Add"}
      </Button>
    </Box>
  );
}
