import React, { useState } from 'react';
import { Card, Grid, Box, Chip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationModal from '../DeleteAddressConfirmation/deleteAddressConfirmation';

const AddressComponent = ({ addresses, onSelectAddress,  onAddAddress, type , shipperLoadData, fetchShipperLoadData}) => {
      const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleDeleteClick = (address) => {
    setSelectedAddress(address);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAddress(null);
  };

    const handleDeleteSuccess = () => {
        fetchShipperLoadData()
        setIsModalOpen(false)
    // Update your address list or perform any other necessary actions after deletion
  };
  
    return (
    <Card
      variant="outlined"
      sx={{
        marginLeft: 5,
        width: '90%',
        marginBottom: 5,
        padding: '10px 0px',
      }}
    >
      <Grid container spacing={1} ml={3}>
        {addresses?.map((address, index) => (
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }} key={index}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'primary.main',
                borderRadius: '16px', // Rounded corners for Chip and DeleteIcon
                padding: '4px', // Optional padding for better spacing
              }}
            >
              <Chip
                label={`${address?.companyName}`}
                color="primary"
                onClick={() => onSelectAddress(address)}
                sx={{ backgroundColor: 'transparent', color: 'white' }}
              />
              <IconButton
                onClick={() => handleDeleteClick(address)}
                sx={{
                  color: 'white', // Same text color as the Chip
                  padding: '4px',
                  marginLeft: '8px', // Optional margin for spacing
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}

        <Grid item xs={2.5} sx={{ display: 'flex' }}>
          <Chip
            label="+ Add address "
            onClick={() =>onAddAddress(type)}
            color="error"
          />
        </Grid>
          </Grid>
    <DeleteConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        address={selectedAddress}
                onDeleteSuccess={handleDeleteSuccess}
                shipperLoadData={shipperLoadData}
                type={type}
      />
    </Card>
  );
};

export default AddressComponent;