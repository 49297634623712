import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const columns = [
  {
    id: 1,
    title: "Name",
  },
  {
    id: 2,
    title: "DOT number",
  },
  {
    id: 3,
    title: "MC number",
  },
  {
    id: 4,
    title: "Created At",
  },
  {
    id: 5,
    title: "Action",
  },
  {
    id: 6,
    title: "Status",
  },
];

export const statusKeys = {
  all: "all",
  active: "active",
  inactive: "inactive",
};

export const statusColor = {
  active: "green",
  inactive: "red",
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
    fontSize: 13,
    padding: 10,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textTransform: "capitalize",
    padding: 10,
    textAlign: "center",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
