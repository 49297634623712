import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    IconButton,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postData } from "../../../services/axiosInstance";



const ChangeSipperstatus = ({ open, onClose, shipperId }) => {

    const [shipperStatus, setShipperStatus] = useState('');

    const submitData = async () => {
        if (shipperStatus !== '') {
            const payload = {
                shipperStatus: shipperStatus
            };
            try {
                const url = `/address/shipper/changeStatus/` + shipperId;
                const response = await postData(url, payload);
                if (response.status === 200) {
                    onClose();
                }
            } catch (error) {
                console.error("Error deleting the address:", error.message);
            }
        }

    };

    return (
        <Modal open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    // width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "8px",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Change Status</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Typography sx={{ mt: 2 }} color='error'>Changing the shipper status will update the current status in the system. Are you sure you want to change the status?</Typography>
                {/* <Grid container  > */}
                <Box
                    component="form"
                    pt={5}
                    pb={5}
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                        background: "#fff",
                        width: "100%",
                    }}
                    autoComplete="off"
                >

                    {/* Select for changing status */}
                    <FormControl variant="outlined" size="small" sx={{ m: 1, minWidth: 250 }}>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            onChange={(e) => setShipperStatus(e.target.value)}
                            label="Status"
                        >
                            <MenuItem value="review">Review</MenuItem>
                            <MenuItem value="approved">Approved</MenuItem>
                            <MenuItem value="rejected">Rejected</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Submit Button */}
                    <Button onClick={() => submitData()} variant="contained" color="primary" sx={{ m: 1 }}>
                        Update
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangeSipperstatus;
