import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postData } from "../../services/axiosInstance";

const DeleteConfirmationModal = ({
  open,
  onClose,
  address,
  onDeleteSuccess,
  shipperLoadData,
  type,
}) => {
  const handleDelete = async () => {
    try {
      const url = `/address/shipper/loads/${shipperLoadData.shipperId}/load/${shipperLoadData._id}/removeConsignor/${type}Address/address/${address._id}`;
      const response = await postData(url);
      if (response.status === 200) {
        onDeleteSuccess();
        //  closeModalFunction({ refetch: true });
      }

      //   if (response.status === 200) {
      //     // Call onDeleteSuccess callback to update the UI after successful deletion
      //     onDeleteSuccess();
      //     onClose(); // Close the modal
      //   } else {
      //     // Handle errors or show a message to the user
      //     console.error("Failed to delete the address");
      //   }
    } catch (error) {
      console.error("Error deleting the address:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Confirm Deletion</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography sx={{ mt: 2 }}>
          Are you sure you want to delete this address?
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
