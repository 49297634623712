import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Auth_Layout/Sidebar/sidebar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button, TablePagination } from "@mui/material";
import { getData } from "../../services/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoadStatus from "../../utils/LoadStatus/loadStatus";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976d2',
    color: theme.palette.common.white,
    fontSize: 13,
    padding:10,
    textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textTransform: "capitalize",
    padding:10,
    textAlign:'center'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllLoads() {
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const [statusKey, setStatusKey] = useState("All");
  const statusButtons = [{_id:'All',label:'All'}, ...LoadStatus] // LoadStatus.concat({_id:'All',label:'All'});

  useEffect(() => {
    const fetchData = async () => {
      const url = `/address/shipper/loads/viewAll?status=${statusKey}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          setListData(response?.data?.data||[]);
        }
      } catch (error) {
        setListData([]);
      }
    };
    fetchData();
  }, [statusKey]);
  const columns = [
    {
      id: 1,
      title: "Load Tag",
    },
    {
      id: 2,
      title: "Ship Tag",
    },
    {
      id: 3,
      title: 'Carrier'
    },
    {
      id: 4,
      title: 'dot Number'
    },
    {
      id: 5,
      title: "Status",
    },
    {
      id: 6,
      title: "Prepaid",
    },
    {
      id: 7,
      title: "Created At",
    },
    {
      id: 8,
      title: "Action",
    },
  ];

  const onClickButton = async (val) => {
    setStatusKey(val);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
        {/* main box start  */}
        <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }} gap={1}>
          {/* breadcrums area  */}
          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/loads">
              Loads
            </Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
          {statusButtons.map((obj) => {
            return (
              <>
                <Button
                  mr={1}
                  key={obj._id}
                  variant={statusKey === obj._id ? "contained" : "outlined"}
                  onClick={() => onClickButton(obj._id)}
                  size="small"
                  sx={{
                    margin: "2px",
                    fontSize: '0.70rem', // Smaller font size
                    padding: '4px 8px', // Adjust padding to make it even smaller
                  }}
                >
                  {obj.label}
                </Button>
              </>
            );
          })}

          {/* main page information area  */}
          <Paper elevation={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {columns?.map((col) => {
                      return (
                        <StyledTableCell key={`key-${col.id}`}>{col.title}</StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData?.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.loadTag}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => navigate(`/shipper/${row.shipperId} `)}
                        underline="hover"
                        color="inherit"
                      >
                        {row?.shipTag}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.carrierInfo ? row?.carrierInfo?.carrierInfo?.allData?.LegalName : "--"}
                      </StyledTableCell>

                      <StyledTableCell>
                        {row?.carrierInfo ? row?.carrierInfo?.carrierInfo?.dotNumber : "--"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.currentStatus ? row?.currentStatus : "--"}
                      </StyledTableCell>

                      <StyledTableCell>
                        {row.isPrepaid ? "Yes" : "No"}
                      </StyledTableCell>

                      <StyledTableCell>
                        {moment(row.createdAt).format("YYYY-MM-DD HH:mm")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="contained"
                           size="small"
                          onClick={() =>
                            navigate(
                              `/shipper/${row.shipperId}/load/${row._id} `
                            )
                          }
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={listData.length}
                rowsPerPage={10}
                page={5}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}
