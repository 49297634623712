import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "../../services/axiosInstance";
import { ADD_ADDRESS_VALIDATION_SCHEMA } from "../../utils/hookFormValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const countries = [
  {
    value: "US",
    label: "US",
  },
  {
    value: "Canada",
    label: "Canada",
  },
];
const AddAddressForm = ({
  openAddModal,
  closeModalFunction,
  shipperId,
  shipperLoadId,
  addressType,
}) => {
  const { control, handleSubmit, reset, watch, setValue, formState: {errors}, setError } = useForm({
    resolver: yupResolver(ADD_ADDRESS_VALIDATION_SCHEMA),
     mode: 'onChange',
    reValidateMode: "onChange",
    defaultValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress: "",
      zipcode: "",
      countryCode: "",
      city: "",
      state: "",
      country: "",
    },
  });

    const { country, zipcode } = watch();
  const modalsubmithandler = async (values) => {
    const payload = {
      ...values,
    };
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/addConsignor/${addressType}Address`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        reset();
        closeModalFunction({ refetch: true });
      }
    } catch (error) {
      // setDisabled(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (zipcode?.length > 4) {
        // get data from zip code
        const url = `/globalAreaDb/infoWithCountryZip?country_code=${country}&zip=${zipcode}`;
        try {
          const response = await getData(url);
          if (response.data.data === false) {
            // openNotificationWithIcon('error', 'Invalid zipcode.');
          }
          setValue("state", response.data.data.admin_name1);
          setError("state", '')
          setValue("city", response.data.data.place_name);
          setError("city", '')
          setValue("countryCode", response.data.data.country_code);
          setError("countryCode", '')
        } catch (error) {
          //   openNotificationWithIcon('error', 'Invalid zipcode.');
        }
      }
    }
    fetchData();
    return () => {
      // perform any cleanup necessary
    };
  }, [country, setError, setValue, zipcode]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAddModal}
      onClose={() => {
        reset()
        closeModalFunction({refetch:false})
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddModal}>
        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit(modalsubmithandler)}
            noValidate
            // onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <div>
              <Typography variant="h6">{`Add ${addressType}`}</Typography>
            </div>
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  margin="normal"
                  id="outlined-required"
                  label="Company"
                  variant="standard"
                  placeholder="Enter Company Name"
                />
              )}
            />
             <Typography variant="body2" color="red">{errors?.companyName?.message}</Typography>

            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      
                      margin="normal"
                      id="outlined-required"
                      label="First Name"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.firstName?.message}</Typography>

              </div>
              <div className="col-md-6">
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // required
                      margin="normal"
                      id="outlined-required"
                      label="Last Name"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.lastName?.message}</Typography>

              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      
                      margin="normal"
                      id="outlined-required"
                      label="Email"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.email?.message}</Typography>

              </div>
              <div className="col-md-6">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      
                      margin="normal"
                      id="outlined-required"
                      label="Phone"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.phone?.message}</Typography>

              </div>
            </div>
            <Controller
              name="streetAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  margin="normal"
                  id="outlined-required"
                  label="Street address"
                />
              )}
            />
              <Typography variant="body2" color="red">{errors?.streetAddress?.message}</Typography>


            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      margin="normal"
                      id="outlined-required"
                      label="Country"
                      select
                      fullWidth
                    >
                    {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                    </TextField>
                  )}
                />
              <Typography variant="body2" color="red">{errors?.country?.message}</Typography>
              </div>
              <div className="col-md-6">
                <Controller
                  name="zipcode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      disabled={country ? false : true}
                      margin="normal"
                      id="outlined-required"
                      label="Zip"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.zipcode?.message}</Typography>

              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      disabled
                      margin="normal"
                      id="outlined-required"
                      label="City"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.city?.message}</Typography>

              </div>
              <div className="col-md-4">
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      disabled
                      margin="normal"
                      id="outlined-required"
                      label="State"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.state?.message}</Typography>

              </div>
              <div className="col-md-4">
                                <Controller
                  name="countryCode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      disabled
                      margin="normal"
                      id="outlined-required"
                      label="Country Code"
                    />
                  )}
                />
              <Typography variant="body2" color="red">{errors?.countryCode?.message}</Typography>


              </div>
            </div>
            <Button
              type="submit"
              
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add
            </Button>
            <Grid
              container
              sx={{
                textAlign: "center",
              }}
            >
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddAddressForm;
