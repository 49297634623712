import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  RadioGroup,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const UpdateCarrierModal = ({ openAddModal, closeModalFunction }) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      status: "",
    },
  });

  const modalsubmithandler = async (values) => {
    const payload = {
      ...values,
    };
    const url = `/address/carrier/add`;

    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        reset();
        openNotificationWithIcon("success", response?.data.message);
        closeModalFunction({ refetch: true });
      }
    } catch (error) {
      // setDisabled(false);
    }
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setValue("status", value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAddModal}
      onClose={closeModalFunction}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddModal}>
        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit(modalsubmithandler)}
            noValidate
            // onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <div>
              <Typography variant="h6">Update Carrier</Typography>
            </div>
            <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  {...field}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="status1"
                    control={<Radio />}
                    label="status1"
                  />
                  <FormControlLabel
                    value="status2"
                    control={<Radio />}
                    label="status2"
                  />
                </RadioGroup>
              )}
            />

            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  margin="normal"
                  id="outlined-required"
                  label="Description"
                  variant="standard"
                  placeholder="Add description"
                />
              )}
            />

            <Button
              type="submit"
              
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
            <Grid
              container
              sx={{
                textAlign: "center",
              }}
            >
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateCarrierModal;
