const LoadStatus = [
    { _id: 'drafted', label: 'Drafted', access: ['agent','superadmin'] },
    { _id: 'booked', label: 'Booked', access: ['agent','superadmin'] },
    { _id: 'tonu', label: 'TONU', access: ['agent','superadmin'] },
    { _id: 'onroute', label: 'On Route', access: ['agent','superadmin'] },
    { _id: 'delivered', label: 'Delivered', access: ['agent','superadmin'] },
    { _id: 'invoicing', label: 'Invoicing', access: ['superadmin'] },
    { _id: 'payments', label: 'Payments', access: ['superadmin'] },
    { _id: 'collection', label: 'Collection', access: ['superadmin'] },
    { _id: 'disputed', label: 'Disputed', access: ['superadmin'] },
    { _id: 'cancelled', label: 'Cancelled', access: ['superadmin'] },
    { _id: 'watch', label: 'Watch', access: ['superadmin'] },
    { _id: 'claimed', label: 'Claimed', access: ['superadmin'] },
    { _id: 'posted', label: 'Posted', access: ['superadmin'] },
    { _id: 'deleted', label: 'Deleted', access: ['agent','superadmin'] },
    { _id: 'completed', label: 'Completed', access: ['superadmin'] },
]

export default LoadStatus;