import * as Yup from "yup";

const ADD_ADDRESS_VALIDATION_SCHEMA = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required("Company Name is required.")
    .min(2, "Company Name must be at least 2 characters long.")
    .max(100, "Company Name cannot exceed 100 characters."),

  firstName: Yup.string()
    .trim()
    .required("First Name is required.")
    .min(2, "First Name must be at least 2 characters long.")
    .max(50, "First Name cannot exceed 50 characters.")
    .matches(/^[A-Za-z]+$/, "First Name can only contain letters."),

  lastName: Yup.string()
    .trim()
    .max(50, "Last Name cannot exceed 50 characters.")
    .matches(/^[A-Za-z]*$/, "Last Name can only contain letters."),

  email: Yup.string()
    .trim()
    .required("Email is required.")
    .email("Please enter a valid email address."),

  phone: Yup.string()
    .trim()
    .required("Phone number is required.")
    .matches(
      /^\+?[0-9]{7,15}$/,
      "Phone number must be valid and contain 7-15 digits."
    ),

  streetAddress: Yup.string()
    .trim()
    .required("Street address is required.")
    .min(5, "Street address must be at least 5 characters long.")
    .max(200, "Street address cannot exceed 200 characters."),

  zipcode: Yup.string()
    .trim()
    .required("Zipcode is required.")
    .matches(/^[0-9]{5,10}$/, "Zipcode must be between 5 and 10 digits."),

  countryCode: Yup.string()
    .trim()
    .required("Country code is required.")
    .matches(
      /^[A-Za-z]{2,3}$/,
      "Country code must be 2-3 alphabetic characters."
    ),

  city: Yup.string()
    .trim()
    .required("City is required.")
    .min(2, "City must be at least 2 characters long.")
    .max(100, "City cannot exceed 100 characters."),

  state: Yup.string()
    .trim()
    .required("State is required.")
    .min(2, "State must be at least 2 characters long.")
    .max(100, "State cannot exceed 100 characters."),

  country: Yup.string()
    .trim()
    .required("Country is required.")
    .min(2, "Country must be at least 2 characters long.")
    .max(100, "Country cannot exceed 100 characters."),
});

export { ADD_ADDRESS_VALIDATION_SCHEMA };
