import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Drawer,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Commodity from "./Commodity";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import CommodityTable from "../CommodityTable/CommodityTable";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import AddAddressForm from "./AddAddressForm";
import AddressComponent from "../AddressComponent/addressComponent";

export default function Consignment({
  fetchShipperLoadData,
  shipperId,
  shipperLoadId,
  ShipperData,
  shipperLoadData,
}) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [edit, setEdit] = useState(Boolean);
  const [editIndex, setEditIndex] = useState(0);
  const [openDrawer, setDrawer] = useState(false);
  const [addressType, setAddressType] = useState("");

  const defaultValues = {
    consignmentDetails: {
      blindShipment: false,
      multiConsignorsConsingee: false,
      displayConsignorsConsingeeName: false,
      isReefer: {
        checked: false,
        temperature: "",
        ventilation: "",
        humidity: "",
      },
    },
    consignor: [
      {
        address: "",
        appointment: false,
        commodityDetails: [
          {
            commodity: "",
            units: "",
            weight: "",
            value: "",
            poNumber: "",
            pickupDate: "",
            pickupTimeFrom: "",
            pickupTimeTo: "",
            loadDescription: "",
          },
        ],
      },
    ],
    consignee: [
      {
        address: "",
        appointment: false,
        commodityDetails: [
          {
            commodity: "",
            units: "",
            weight: "",
            value: "",
            poNumber: "",
            pickupDate: "",
            pickupTimeFrom: "",
            pickupTimeTo: "",
            loadDescription: "",
          },
        ],
      },
    ],
  };

  const [formValues, setFormValues] = useState(
    {
      consignmentDetails: shipperLoadData.consignmentDetails,
      consignor: shipperLoadData.consignor,
      consignee: shipperLoadData.consignee,
    } || defaultValues
  );
  const [isReferCheckBox, setIsReferCheckBox] = useState(
    shipperLoadData.consignmentDetails?.isReefer?.checked
  );
  const [allList, setAllList] = useState(shipperLoadData.consignor);
  const [editRow, setEditRow] = useState(null);
  const [editDetails, setEditDetails] = useState(null);

  const { control, handleSubmit, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const [type, setType] = useState("");
  const [selectedIndex, setSelectedindex] = useState("");

  const toggleDrawer = async (elem, index, type) => {
    await setEditRow(null);
    setType(type);
    setSelectedindex(index);
    setDrawer((prev) => !prev);
  };

  const isReferCheckbox = (event) => {
    if (event.target.checked) {
      setIsReferCheckBox(true);
      setValue("consignmentDetails.isReefer.checked", event.target.checked);
    } else {
      setIsReferCheckBox(false);
    }
  };
  const openModalFunction = (type) => {
    setAddressType(type);
    setOpenAddModal(true);
  };
  const closeModalFunction = ({ refetch }) => {
    setOpenAddModal(false);
    if (refetch) {
      fetchShipperLoadData();
    }
  };

  const deleteEntry = (row, index, type, addressIndex) => {
    setFormValues((prev) => {
      const updateType = prev[type];
      const item = updateType[addressIndex];
      const updateCommodity = item?.commodityDetails?.filter(
        (elem, idx) => idx !== index
      );
      // console.log({ updateCommodity });
      const updateItem = {
        ...item,
        commodityDetails: updateCommodity,
      };
      const updatedItem = updateType?.map((elem, idx) => {
        if (idx === addressIndex) {
          return updateItem;
        } else {
          return elem;
        }
      });
      return { ...prev, [type]: updatedItem };
    });
  };

  const editEntry = (row, index, type, addressIndex) => {
    setEditDetails({ index, type, addressIndex });
    setEditRow(row);
    setDrawer(true);
  };
  const updateCommodity = (obj) => {
    const { index, type, addressIndex } = editDetails;
    setFormValues((prev) => {
      const updateType = prev[type];
      const item = updateType[addressIndex];
      const updateCommodity = item?.commodityDetails.map((elem, idx) => {
        if (idx === index) {
          return obj;
        } else {
          return elem;
        }
      });
      const updateItem = {
        ...item,
        commodityDetails: updateCommodity,
      };
      const updatedItem = updateType?.map((elem, idx) => {
        if (idx === addressIndex) {
          return updateItem;
        } else {
          return elem;
        }
      });
      return { ...prev, [type]: updatedItem };
    });
    setDrawer((prev) => !prev);
  };
  const onSubmitShipperHandler = async (values) => {
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/editConsignment`;
    try {
      const response = await postData(url, formValues);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
      }
    } catch (error) {
      alert("error");
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  const onSelectAddress = (e) => {};
  const attachConsignor = () => {
    const newConsignor = {
      appointment: false,
      commodityDetails: [],
      address: "",
    };

    setFormValues((prev) => {
      const consignor = prev.consignor;
      consignor.push(newConsignor);
      return { ...prev, consignor: consignor };
    });
  };

  const attachConsignee = () => {
    const newConsignee = {
      appointment: false,
      commodityDetails: [],
      address: "",
    };

    setFormValues((prev) => {
      const consignee = prev.consignee;
      consignee.push(newConsignee);
      return { ...prev, consignee: consignee };
    });
  };

  const addCommodity = (obj) => {
    setFormValues((prev) => {
      const updateType = prev[type];
      const item = updateType[selectedIndex];
      const updateCommodity = item?.commodityDetails;
      updateCommodity.push(obj);
      const updateItem = {
        ...item,
        commodityDetails: updateCommodity,
      };
      const updatedItem = updateType?.map((elem, idx) => {
        if (idx === selectedIndex) {
          return updateItem;
        } else {
          return elem;
        }
      });

      return { ...prev, [type]: updatedItem };
    });
    setDrawer((prev) => !prev);
  };

  const selectAddress = (address, type, index) => {
    setFormValues((prev) => {
      const updateType = prev[type];
      const item = updateType[index];
      item.address = address;

      const updatedItem = updateType?.map((elem, idx) => {
        if (idx === index) {
          return item;
        } else {
          return elem;
        }
      });
      return { ...prev, [type]: updatedItem };
    });
  };

  const clickAppointment = (evt, type, selectedIdx) => {
    setFormValues((prev) => {
      const updateType = prev[type];
      const item = updateType[selectedIdx];
      item.appointment = evt.target.checked;

      const updatedItem = updateType?.map((elem, idx) => {
        if (idx === selectedIdx) {
          return item;
        } else {
          return elem;
        }
      });
      return { ...prev, [type]: updatedItem };
    });
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitShipperHandler)}
        pl={1}
        pt={5}
        pb={5}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          background: "#fff",
          width: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Typography ml={5} mb={2} variant="h5">
            Consignment Details
          </Typography>
          <Grid item>
            <Paper
              variant="outlined"
              sx={{
                marginLeft: 5,
                width: "90%",
                marginBottom: 5,
                padding: "10px 0px",
              }}
            >
              <Grid container spacing={1} ml={3}>
                <Grid item xs={3.5}>
                  <Controller
                    name="consignmentDetails.blindShipment"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Blind Shipment"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <Controller
                    name="consignmentDetails.multiConsignorsConsingee"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Multiple Consignment/Consignors"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <Controller
                    name="consignmentDetails.displayConsignorsConsingeeName"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Display Consignment"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3.5}></Grid>
              </Grid>
              <div>
                <Grid container spacing={1} ml={3}>
                  <Grid item xs={2.5}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={isReferCheckbox}
                            checked={isReferCheckBox}
                          />
                        }
                        label="Is this Refer"
                      />
                    </FormGroup>
                  </Grid>

                  {isReferCheckBox ? (
                    <>
                      <Grid item xs={2.5}>
                        <Controller
                          name="consignmentDetails.isReefer.temperature"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              margin="normal"
                              id="outlined-required"
                              label="Enter Temperature"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <Controller
                          name="consignmentDetails.isReefer.ventilation"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              margin="normal"
                              id="outlined-required"
                              label="Enter Ventilation"
                            />
                          )}
                        />
                      </Grid>{" "}
                      <Grid item xs={2.5}>
                        <Controller
                          name="consignmentDetails.isReefer.humidity"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              margin="normal"
                              id="outlined-required"
                              label="Enter Humidity"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={3.5}></Grid>
                      <Grid item xs={3.5}></Grid>
                      <Grid item xs={3.5}></Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Paper>
          </Grid>
        </div>
        <div>
          <Typography ml={5} mb={2} variant="h5">
            Pickup/Consignor <small>Address</small>
          </Typography>
          {shipperLoadData && (
            <AddressComponent
              addresses={shipperLoadData?.consignorAddress}
              onSelectAddress={onSelectAddress}
              onAddAddress={openModalFunction}
              type="consignor"
              shipperLoadData={shipperLoadData}
              fetchShipperLoadData={fetchShipperLoadData}
            />
          )}
        </div>
        <br />
        <hr />
        <br />
        <div>
          <Typography ml={5} mb={2} variant="h5">
            Consignor &nbsp; &nbsp;{" "}
            <Button
              sx={{ marginLeft: 5, width: "20%" }}
              onClick={() => attachConsignor()}
              variant="contained"
            >
              Add Consignor
            </Button>
          </Typography>
          <Card
            variant="outlined"
            sx={{
              marginLeft: 5,
              width: "90%",
              marginBottom: 5,
              padding: "10px 0px",
              background: "aliceblue",
            }}
          >
            <Grid container>
              {formValues.consignor?.length > 0 &&
                formValues.consignor?.map((e, i) => {
                  return (
                    <>
                      <div
                        style={{
                          marginLeft: 40,
                          width: "100%",
                        }}
                      >
                        {shipperLoadData?.consignorAddress?.length > 0 && (
                          <SelectDropdown
                            addressList={shipperLoadData?.consignorAddress}
                            type={"consignor"}
                            addressIndex={i}
                            selectAddress={selectAddress}
                            selectedAddress={e?.address}
                          />
                        )}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={e.appointment}
                                onChange={(evt) =>
                                  clickAppointment(evt, "consignor", i)
                                }
                              />
                            }
                            label="Appointment"
                          />
                        </FormGroup>
                      </div>
                      <br />
                      <br />
                      <div
                        style={{
                          marginLeft: 0,
                          marginTop: 10,
                          width: "100%",
                        }}
                      >
                        <Typography ml={5} mb={2} variant="h6">
                          Commodity Details
                          <Button
                            sx={{
                              marginLeft: 5,
                              marginRight: 10,
                              width: "20%",
                              float: "right",
                            }}
                            onClick={() => toggleDrawer(e, i, "consignor")}
                            variant="contained"
                            color="success"
                          >
                            Add Commodity
                          </Button>
                        </Typography>

                        {formValues.consignor[i].commodityDetails.length > 0 ? (
                          <CommodityTable
                            dataArray={formValues.consignor[i].commodityDetails}
                            deleteEntry={deleteEntry}
                            type={"consignor"}
                            addressIndex={i}
                            editEntry={editEntry}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}
            </Grid>
          </Card>
        </div>
        <div>
          <Typography ml={5} mb={2} variant="h5">
            Delivery/Consignee <small>Address</small>
          </Typography>
          <AddressComponent
            addresses={shipperLoadData?.consigneeAddress}
            onSelectAddress={onSelectAddress}
            onAddAddress={openModalFunction}
            type="consignee"
            shipperLoadData={shipperLoadData}
            fetchShipperLoadData={fetchShipperLoadData}
          />
        </div>
        <br />
        <hr />
        <br />

        <div>
          <Typography ml={5} mb={2} variant="h5">
            Consignee &nbsp; &nbsp;{" "}
            <Button
              sx={{ marginLeft: 5, width: "20%" }}
              // onClick={() => toggleDrawer()}
              onClick={() => attachConsignee()}
              variant="contained"
            >
              Add Consignee
            </Button>
          </Typography>
          <Card
            variant="outlined"
            sx={{
              marginLeft: 5,
              width: "90%",
              marginBottom: 5,
              padding: "10px 0px",
              background: "beige",
            }}
          >
            <Grid container>
              {formValues.consignee?.length > 0 &&
                formValues.consignee?.map((e, i) => {
                  return (
                    <>
                      <div
                        style={{
                          marginLeft: 40,
                          width: "100%",
                        }}
                      >
                        {shipperLoadData?.consigneeAddress?.length > 0 && (
                          <SelectDropdown
                            addressList={shipperLoadData?.consigneeAddress}
                            type={"consignee"}
                            addressIndex={i}
                            selectAddress={selectAddress}
                            selectedAddress={e?.address}
                          />
                        )}

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={e.appointment}
                                onChange={(evt) =>
                                  clickAppointment(evt, "consignee", i)
                                }
                              />
                            }
                            label="Appointment"
                          />
                        </FormGroup>
                      </div>
                      <br />
                      <br />
                      <div
                        style={{
                          marginLeft: 0,
                          marginTop: 10,
                          width: "100%",
                        }}
                      >
                        <Typography ml={5} mb={2} variant="h6">
                          Commodity Details &nbsp; &nbsp;{" "}
                          <Button
                            sx={{
                              marginLeft: 5,
                              marginRight: 10,
                              width: "20%",
                              float: "right",
                            }}
                            onClick={() => toggleDrawer(e, i, "consignee")}
                            variant="contained"
                            color="success"
                          >
                            Add Commodity
                          </Button>
                        </Typography>

                        {formValues.consignee[i].commodityDetails.length > 0 ? (
                          <CommodityTable
                            dataArray={formValues.consignee[i].commodityDetails}
                            deleteEntry={deleteEntry}
                            type={"consignee"}
                            addressIndex={i}
                            editEntry={editEntry}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
                })}
            </Grid>
          </Card>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button
              variant="contained"
              sx={{
                marginTop: "10px",
                marginLeft: "40px",
                width: "20%",
              }}
              type="submit"
            >
              {shipperLoadId ? "Update" : "Save"}
            </Button>
          </div>
        </div>

        <div
          className="row "
          style={{
            padding: "49px",
          }}
        ></div>
      </Box>
      <Drawer
        anchor={"right"}
        open={openDrawer}
        PaperProps={{
          sx: { width: "35%" },
        }}
        onClose={() => toggleDrawer()}
      >
        <Box sx={{ zIndex: 1000000, width: "100%" }}>
          <Commodity
            edit={edit}
            editIndex={editIndex}
            allListData={allList}
            // onDataUpdate={handleDataUpdate}
            addCommodity={addCommodity}
            updateCommodity={updateCommodity}
            editRow={editRow}
          />
        </Box>
      </Drawer>
      <AddAddressForm
        openAddModal={openAddModal}
        closeModalFunction={closeModalFunction}
        shipperId={shipperId}
        shipperLoadId={shipperLoadId}
        addressType={addressType}
      />
    </>
  );
}
