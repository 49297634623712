import React from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";

const ChangeCarrierStatus = ({ open, onClose, carrierId }) => {
    const submitData = async () => {
            try {
                const url = `/address/carrier/update/status/` + carrierId;
                const response = await postData(url);
                if (response.status === 200) {
                    onClose({ refetch: true });
                    openNotificationWithIcon("success", response?.data.message);
                }
            } catch (error) {
                openNotificationWithIcon("error", error?.response?.data?.message);
                onClose({ refetch: false });
            }

    };

    return (
        <Modal open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "8px",
                    boxShadow: 24,
                    p: 4,
                    justifyContent: "center",
                    alignItems:"center"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Change Status</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Typography sx={{ mt: 2 }} color='error'>Changing the carrier status will update the current status in the system. Are you sure you want to change the status?</Typography>
                {/* <Grid container  > */}
                <Box
                    component="form"
                    pt={5}
                    pb={5}
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                        background: "#fff",
                        width: "50%",
                    }}
                    autoComplete="off"
                >
                     <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                     <Button variant="outlined" color="error" sx={{ m: 1 }} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => submitData()} variant="contained" color="primary" sx={{ m: 1 }}>
                        Update
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangeCarrierStatus;
